var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"child"},[(_vm.layout === 'filters')?_c('div',_vm._l((_vm.division),function(n,j){return _c('div',{key:j},[_vm._l((n),function(field,i){return [_c('Field',{key:i,attrs:{"field":Object.values(field)[0],"layout":_vm.layout},model:{value:(_vm.new_val[Object.keys(field)[0]]),callback:function ($$v) {_vm.$set(_vm.new_val, Object.keys(field)[0], $$v)},expression:"new_val[Object.keys(field)[0]]"}}),(i !== n.length - 1)?_c('v-divider',{staticClass:"my-4"}):_vm._e()]})],2)}),0):_c('div',{staticClass:"overflow-hidden"},[_c('v-row',{attrs:{"no-gutters":""}},[(
			            _vm.mode === 'creation' ||
			            _vm.mode === 'multiple_creation' ||
			            _vm.mode === 'fast-edition'
			        )?_c('v-col',{staticClass:"sub-title-2 font-weight-bold pa-3"},[(_vm.mode === 'creation' && _vm.title)?_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("single_trad.FieldGroup.creation_title", { type: _vm.title }))+" ")]):_vm._e()]):_vm._e(),(
			            _vm.mode === 'creation' ||
			            _vm.mode === 'multiple_creation' ||
			            _vm.mode === 'fast-edition'
					)?_c('v-col',{staticClass:"pr-3",attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('div',{staticClass:"caption-1 mr-3",class:_vm.show_all ? 'black2white--text' : 'cell4--text'},[_vm._v(" "+_vm._s(_vm.disabled_show_all || _vm.show_all ? _vm.$t("single_trad.FieldGroup.all_shown") : _vm.$t("single_trad.FieldGroup.show_all"))+" ")]),(_vm.fields)?_c('v-switch',{staticStyle:{"margin-right":"-14px"},attrs:{"dense":"","inset":"","disabled":_vm.disabled_show_all},model:{value:(_vm.show_all),callback:function ($$v) {_vm.show_all=$$v},expression:"show_all"}}):_vm._e()],1)],1):_vm._e(),_vm._l((_vm.division),function(n,j){return _c('v-col',{key:j,style:((_vm.mode === 'edition' || _vm.mode === 'readonly') && !n.find(function (e) { return Object.values(e)[0].type === 'id_media'; }) ? { minWidth: '290px' } : {}),attrs:{"cols":_vm.mode === 'edition' || _vm.mode === 'readonly' ? '' : '12'}},[_vm._l((n),function(field,i){return [_c('v-expand-transition',{key:i},[(_vm.show)?_c('Field',{directives:[{name:"show",rawName:"v-show",value:(
				                  _vm.mode === 'creation' ||
				                  _vm.mode === 'multiple_creation' ||
				                  _vm.mode === 'fast-edition'
				                    ? Object.values(field)[0].required || _vm.show_all
				                    : true
				                ),expression:"\n\t\t\t\t                  mode === 'creation' ||\n\t\t\t\t                  mode === 'multiple_creation' ||\n\t\t\t\t                  mode === 'fast-edition'\n\t\t\t\t                    ? Object.values(field)[0].required || show_all\n\t\t\t\t                    : true\n\t\t\t\t                "}],key:i,attrs:{"oldVal":_vm.old_val[Object.keys(field)[0]],"field":Object.values(field)[0],"fieldName":Object.keys(field)[0],"mode":_vm.mode === 'fast-edition' || _vm.mode === 'modification' || _vm.mode === 'register' ? 'creation' : _vm.mode,"pevents":_vm.events,"path":_vm.path,"pathId":_vm.pathId},on:{"change":function($event){_vm.mode !== 'edition' ? _vm.validateCreation() : ''},"newItemCreated":function (e) {
				                    _vm.$emit('newItemCreated', {
				                      item: e,
				                      field: Object.keys(field)[0],
				                    });
			                    },"fileField":function (e) {
				                    _vm.file_field_name = e;
				                    _vm.$emit('fileField', e);
				                  },"ready":function($event){_vm.ready--}},model:{value:(_vm.new_val[Object.keys(field)[0]]),callback:function ($$v) {_vm.$set(_vm.new_val, Object.keys(field)[0], $$v)},expression:"new_val[Object.keys(field)[0]]"}}):_vm._e()],1)]})],2)}),_c('v-col',{staticClass:"px-3",attrs:{"cols":"12"}},[(_vm.mode === 'creation' || _vm.mode === 'multiple_creation' || !_vm.mode)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"caption-1"},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"8"}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.$t("single_trad.FieldGroup.required_fields"))+" ")],1)])],1):_vm._e()],1)],2),_c('v-row',{attrs:{"no-gutters":""}},[((_vm.mode === 'creation' || _vm.mode === 'modification' || _vm.mode === 'fast-edition') && _vm.mode !== 'readonly')?_c('v-col',{staticClass:"px-3",attrs:{"cols":"12"}},[(_vm.mode === 'modification' || _vm.mode === 'fast-edition')?_c('v-row',[_c('v-col',[_c('Button',{attrs:{"disabled":_vm.cancel_save || _vm.item_empty,"large":"","block":"","color":"celldark","dark":!_vm.cancel_save && !_vm.item_empty,"label":_vm.$tc('global.action.cancel', 1),"click":function () { return _vm.cancel(); }}})],1),_c('v-col',[_c('Button',{attrs:{"disabled":!_vm.save_ok || !_vm.creation_ok,"loading":_vm.loading,"large":"","block":"","color":"primary","label":_vm.$tc('global.action.save', 1),"click":function () { return _vm.saveChanges(); }}})],1)],1):_c('v-row',[_c('v-col',[_c('Button',{attrs:{"disabled":_vm.item_empty,"large":"","block":"","dark":!_vm.item_empty,"color":"celldark","label":_vm.mode !== 'edition'
				                    ? _vm.$tc('global.action.clear', 1)
				                    : _vm.$tc('global.action.cancel', 1),"click":function () { return _vm.clearItem(); }}})],1),_c('v-col',[_c('Button',{attrs:{"loading":_vm.loading,"disabled":!_vm.creation_ok,"large":"","block":"","color":"primary","label":_vm.mode !== 'edition'
				                    ? _vm.$tc('global.action.create', 1)
				                    : _vm.$tc('global.action.save', 1),"click":function () { return _vm.createItem(); }}})],1)],1)],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }