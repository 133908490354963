<template>
	<div ref="child">
		<div v-if="layout === 'filters'">
			<div v-for="(n, j) in division" :key="j">
				<template v-for="(field, i) in n">
					<Field
							:key="i"
							v-model="new_val[Object.keys(field)[0]]"
							:field="Object.values(field)[0]"
							:layout="layout"
					/>
					<v-divider v-if="i !== n.length - 1" class="my-4"/>
				</template>
			</div>
		</div>

		<div v-else class="overflow-hidden">
			<v-row no-gutters>
				<v-col
						class="sub-title-2 font-weight-bold pa-3"
						v-if="
				            mode === 'creation' ||
				            mode === 'multiple_creation' ||
				            mode === 'fast-edition'
				        "
				>
					<div v-if="mode === 'creation' && title" class="mb-1">
						{{ $t("single_trad.FieldGroup.creation_title", { type: title }) }}
					</div>
				</v-col>
				<v-col
						cols="auto"
						class="pr-3"
						v-if="
				            mode === 'creation' ||
				            mode === 'multiple_creation' ||
				            mode === 'fast-edition'
						"
				>
					<v-row align="center" class="mx-0">
						<div
								class="caption-1 mr-3"
								:class="show_all ? 'black2white--text' : 'cell4--text'"
						>
							{{
								disabled_show_all || show_all
								? $t("single_trad.FieldGroup.all_shown")
								: $t("single_trad.FieldGroup.show_all")
							}}
						</div>
						<v-switch
								v-if="fields"
								v-model="show_all"
								dense
								inset
								:disabled="disabled_show_all"
								style="margin-right: -14px"
						/>
					</v-row>
				</v-col>
				<v-col
						v-for="(n, j) in division"
						:style="(mode === 'edition' || mode === 'readonly') && !n.find(e => Object.values(e)[0].type === 'id_media') ? { minWidth: '290px' } : {}"
						:key="j"
						:cols="mode === 'edition' || mode === 'readonly' ? '' : '12'"
				>
					<template v-for="(field, i) in n">
						<v-expand-transition :key="i">
							<Field
									v-if="show"
									v-show="
					                  mode === 'creation' ||
					                  mode === 'multiple_creation' ||
					                  mode === 'fast-edition'
					                    ? Object.values(field)[0].required || show_all
					                    : true
					                "
									:key="i"
									v-model="new_val[Object.keys(field)[0]]"
									:oldVal="old_val[Object.keys(field)[0]]"
									:field="Object.values(field)[0]"
									:fieldName="Object.keys(field)[0]"
									:mode="mode === 'fast-edition' || mode === 'modification' || mode === 'register' ? 'creation' : mode"
									:pevents="events"
									:path="path"
									@change="mode !== 'edition' ? validateCreation() : ''"
									@newItemCreated="(e) => {
					                    $emit('newItemCreated', {
					                      item: e,
					                      field: Object.keys(field)[0],
					                    });
				                    }"
									@fileField="(e) => {
					                    file_field_name = e;
					                    $emit('fileField', e);
					                  }
					                "
									@ready="ready--"
									:pathId="pathId"
							/>
						</v-expand-transition>
					</template>
				</v-col>

				<v-col cols="12" class="px-3">
					<v-row
							v-if="mode === 'creation' || mode === 'multiple_creation' || !mode"
							justify="end"
					>
						<v-col cols="auto">
							<div class="caption-1">
								<v-icon size="8" class="mb-2">mdi-star</v-icon>
								{{ $t("single_trad.FieldGroup.required_fields") }}
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<v-row no-gutters>
				<v-col
						cols="12"
						class="px-3"
						v-if="(mode === 'creation' || mode === 'modification' || mode === 'fast-edition') && mode !== 'readonly'"
				>
					<v-row v-if="mode === 'modification' || mode === 'fast-edition'">
						<v-col>
							<Button
									:disabled="cancel_save || item_empty"
									large
									block
									color="celldark"
									:dark="!cancel_save && !item_empty"
									:label="$tc('global.action.cancel', 1)"
									:click="() => cancel()"
							/>
						</v-col>
						<v-col>
							<Button
									:disabled="!save_ok || !creation_ok"
									:loading="loading"
									large
									block
									color="primary"
									:label="$tc('global.action.save', 1)"
									:click="() => saveChanges()"
							/>
						</v-col>
					</v-row>
					<v-row v-else>
						<v-col>
							<Button
									:disabled="item_empty"
									large
									block
									:dark="!item_empty"
									color="celldark"
									:label="
					                  mode !== 'edition'
					                    ? $tc('global.action.clear', 1)
					                    : $tc('global.action.cancel', 1)
					                "
									:click="() => clearItem()"
							/>
						</v-col>
						<v-col>
							<Button
									:loading="loading"
									:disabled="!creation_ok"
									large
									block
									color="primary"
									:label="
					                  mode !== 'edition'
					                    ? $tc('global.action.create', 1)
					                    : $tc('global.action.save', 1)
					                "
									:click="() => createItem()"
							/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: "FieldGroup",
		props: {
			title: {},
			value: {default: {}},
			layout: {},
			fields: {},
			mode: {},
			split: {default: 1},
			path: "",
			pathId: {},
			pevents: {},
			show: {default: true},
		},
		components: {
			Field: () => import("@/components/Field.vue"),
			Button: () => import("@/components/ui/Button.vue"),
		},
		data() {
			return {
				creation_ok: false,
				save_ok: false,
				item_empty: true,
				cancel_save: true,
				old_val: {},
				loading: false,
				bottom_sheet: false,
				events: null,
				children_changed: [],
				file_field_name: null,
				show_all: false,
				ready: Object.keys(this.fields).length,
			};
		},
		computed: {
			disabled_show_all() {
				let count = 0;

				for (let key in this.fields) {
					if (this.fields[key].required === false) {
						count++;
					}
				}
				if (count > 0) {
					if (count === Object.keys(this.fields).length) {
						this.show_all = true;
					}
					return false;
				} else {
					return true;
				}
			},
			new_val: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val);
				},
			},
			division() {
				let res = [];
				let reste = Object.keys(this.fields).length % this.split;

				for (let i = 0; i < this.split; i++) {
					res.push(
						Math.trunc(Object.keys(this.fields).length / this.split) +
						(reste === 0 ? 0 : 1)
					);
					reste = reste === 0 ? 0 : reste - 1;
				}

				let fields = [];
				let tmp = Object.keys(this.fields);
				let k = 0;

				for (let i = 0; i < res.length; i++) {
					let array = [];

					for (let j = 0; j < res[i]; j++) {
						let obj = {};

						obj[tmp[k]] = this.fields[tmp[k]];
						array.push(obj);
						k++;
					}

					fields.push(array);
				}

				return fields;
			},
		},
		watch: {
			ready(val) {
				if (val === 0) {
					this.$emit("ready");
				}
			},
			item_empty(val) {
				this.$emit("empty", val);
			},
			bottom_sheet(val) {
				this.$emit("bottomSheet", val);
			},
			children_changed(val) {
				this.pevents.notify("fields-changed", val);
			},
			show(val) {
				if (val) {
					this.old_val = JSON.parse(JSON.stringify(this.value));
					this.events.notify("refresh-editor");
					this.validateCreation();
				}
			},
		},
		methods: {
			cancel() {
				this.events.notify("close-fast");
				this.$emit("close");
			},
			clearItem() {
				this.new_val = {};
				this.events.notify("refresh-editor");

				this.$nextTick(() => {
					Object.keys(this.fields).forEach((e) => {
						if (this.fields[e].type === "boolean" && this.fields[e].required) {
							this.$set(this.new_val, e, false);
						}
					});

					this.validateCreation();
				});
			},
			closeBottomSheet() {
				this.events.notify("close");
				this.bottom_sheet = false;
			},
			createItem() {
				let data = {...this.new_val};

				this.loading = true;

				this.$emit("loading", true);

				if (this.file_field_name !== null) {
					this.$wsc.toFormData(data, (succ, header) => {
						this.$wsc.createItem(
							this.path,
							succ,
							(su) => {
								this.loading = false;
								this.$emit("created", su);
								this.$store.dispatch("snackbar/success", this.$t("snackbar.FieldGroup.createAll.success")
								);
							},
							(fail) => {
								this.loading = false;
							},
							header
						);
					});
				} else {
					this.$wsc.createItem(
						this.path,
						data,
						(success) => {
							this.loading = false;
							this.$emit("created", success);
							this.$store.dispatch("snackbar/success", this.$t("snackbar.FieldGroup.createAll.success"));
						},
						(fail) => {
							this.loading = false;
						}
					);
				}
			},
			saveChanges() {
				this.loading = true;

				let data = {};

				for (let key of this.children_changed) {
					if (this.fields[key].type === "datetime" && this.new_val[key] === "") {
						data[key] = null;
					} else {
						data[key] = this.new_val[key];
					}
				}

				if (this.file_field_name !== null) {
					this.$wsc.toFormData(data, (succ, header) => {
						this.$wsc.patchItem(this.path, this.pathId, succ, (su) => {
							this.loading = false;
							this.bottom_sheet = false;
							this.new_val = su;
							this.old_val = JSON.parse(JSON.stringify(su));

							if (this.mode !== "fast-edition")
								this.events.notify("close-saved");

							this.$emit("saved", su);
							this.$emit("close");
							this.$store.dispatch("snackbar/success", this.$t("snackbar.EntityField.saveChanges.success"));
						}, (fail) => {
							this.loading = false;
						}, header);
					});
				}
				else {
					this.$wsc.patchItem(this.path, this.pathId, data, (success) => {
						this.loading = false;
						this.bottom_sheet = false;
						this.new_val = success;
						this.old_val = JSON.parse(JSON.stringify(success));

						if (this.mode !== "fast-edition") this.events.notify("close-saved");
						this.$emit("saved", success);
						this.$emit("close");
						this.$store.dispatch(
							"snackbar/success",
							this.$t("snackbar.EntityField.saveChanges.success")
						);
					}, (fail) => {
						this.loading = false;
					});
				}
			},
			setUpHooks() {
				if (this.layout !== "filters") {
					this.events = new this.$NVEvent("FieldGroup");

					if (typeof this.pevents !== "undefined") {
						this.pevents.wait("close-children", () => {
							this.events.notify("close");
						});

						this.pevents.wait("parent-saved", (val) => {
							this.old_val = JSON.parse(JSON.stringify(val));
						});

						this.pevents.wait("close-fast", () => {
							this.events.notify("close-fast");
						});

						this.pevents.wait("edit-closed", (item) => {
						});
					}
					this.events.wait("child-closed", (item, id) => {
						this.bottom_sheet = this.children_changed.length !== 0;
					});

					this.events.wait("child-saving", (val) => {
						this.loading = val;
					});

					this.events.wait("child-saved", (item, val) => {
						let index = this.children_changed.indexOf(item);

						this.children_changed.splice(index, 1);
						this.bottom_sheet = this.children_changed.length !== 0;

						this.new_val = val;
						this.old_val = JSON.parse(JSON.stringify(val));

						this.$emit("saved", val);
					});

					this.events.wait("child-changed", (item, val) => {
						if (val) {
							this.cancel_save = false;
							this.save_ok = true;
							let index = this.children_changed.indexOf(item);

							if (index === -1) this.children_changed.push(item);
						}
						else {
							this.cancel_save = true;
							this.save_ok = false;

							let index = this.children_changed.indexOf(item);

							if(index !== -1) this.children_changed.splice(index, 1);
						}
						this.bottom_sheet = this.children_changed.length !== 0;
					});

					this.events.wait("update", (val) => {
						this.pevents.notify('update', val)
					})
				}
			},
			validateCreation() {
				if (JSON.stringify(this.new_val).length === 2) {
					this.item_empty = true;
				} else {
					for (let key in this.new_val) {
						if (this.new_val[key]) {
							this.item_empty = false;
							break;
						} else {
							this.item_empty = true;
						}
					}
				}

				let count = 0;

				for (let key in this.fields) {
					let type = this.fields[key].type;
					let current_val = this.new_val[key];

					if (this.fields[key].required && type !== "multiselect") {
						if (
							current_val === "" ||
							current_val === null ||
							typeof current_val === "undefined"
						) {
							this.creation_ok = false;
							this.$emit("formOk", false);
							return;
						} else {
							count++;
						}
					}
				}

				if (this.item_empty) {
					this.creation_ok = false;
					this.$emit("formOk", false);
				} else {
					this.creation_ok = true;
					this.$emit("formOk", true);
				}
			},
		},
		created() {
			this.setUpHooks();
			this.old_val = JSON.parse(JSON.stringify(this.value));
		},
		mounted() {
			this.validateCreation();
			this.$emit("loaded", this.$refs.child);
		},
	};
</script>

<style scoped>
</style>
